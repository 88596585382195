<template>
  <b-container fluid class="py-3">
    <h5>DIISI OLEH PETUGAS HB</h5>
    <b-form>
      <b-form-group label="Nama Petugas HB">
        <b-form-input
          id="nama-petugas"
          v-model="form.namaPetugas"
          disabled
        ></b-form-input>
      </b-form-group>

      <b-form-group label="HB Meter">
        <b-form-input
          id="hb-meter"
          type="text"
          v-model="form.hbMeter"
          disabled
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Golongan Darah">
        <b-form-input
          id="blood_type"
          type="text"
          v-model="form.golonganDarah"
          disabled
        ></b-form-input>
      </b-form-group>
    </b-form>
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BForm,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BFormGroup,
  BCard,
  BCardHeader,
  BCardBody,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BForm,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
    BFormGroup,
    BCard,
    BCardHeader,
    BCardBody,
  },

  props: {
    hb_examination: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: {
        namaPetugas: "",
        hbMeter: "",
        beratJenis1_063: "",
        beratJenis1_062: "",
        golonganDarah: "",
      },
    };
  },

  watch: {
    hb_examination: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.initializeForm(newVal);
        }
      },
    },
  },

  methods: {
    initializeForm(hb_examination) {
      this.form.namaPetugas = hb_examination?.officer_name || "";
      this.form.hbMeter = hb_examination?.hb_meter
        ? `${hb_examination.hb_meter} gr/dl`
        : "";
      this.form.beratJenis1_063 =
        this.normalizeValue(hb_examination?.kupper_sulfat) || "";
      this.form.beratJenis1_062 =
        this.normalizeValue(hb_examination?.kupper_sulfat) || "";
      const bloodType = hb_examination?.blood_type || "";
      this.form.golonganDarah = bloodType.includes("+")
        ? `${bloodType.replace(/\+|-/g, "")} Pos`
        : `${bloodType.replace(/\+|-/g, "")} Neg`;
    },
    normalizeValue(value) {
      if (typeof value === "string") {
        return value.toLowerCase();
      }
      return value;
    },
  },
};
</script>
