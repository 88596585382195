<template>
  <b-container fluid class="py-3">
    <h5>DIISI OLEH ADMINISTRASI</h5>
    <b-form-group label="No.KTP/SIM/Passpor">
      <b-form-radio-group v-model="form.kartuIdentitas" disabled>
        <b-form-radio value="ktp">KTP</b-form-radio>
        <b-form-radio value="sim">SIM</b-form-radio>
        <b-form-radio value="passpor">Passpor</b-form-radio>
      </b-form-radio-group>
    </b-form-group>

    <b-form-group label="Riwayat Donor Sebelumnya">
      <b-form-textarea
        v-model="form.riwayatDonorSebelumnya"
        rows="3"
        disabled
      ></b-form-textarea>
    </b-form-group>

    <b-row>
      <b-col cols="12" md="6">
        <b-form-group label="Kapan donor terakhir kali">
          <b-form-input
            type="text"
            v-model="form.riwayatDonorTerakhir"
            disabled
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6">
        <b-form-group label="Sekarang donor yang ke">
          <b-form-input
            type="number"
            v-model="form.jumlahDonor"
            disabled
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group label="Macam donor">
      <b-form-radio-group v-model="form.macamDonor" disabled>
        <b-form-radio value="sukarela">Sukarela</b-form-radio>
        <b-form-radio value="pengganti">Pengganti</b-form-radio>
      </b-form-radio-group>
    </b-form-group>

    <b-form-group label="Nama Petugas">
      <b-form-input
        type="text"
        v-model="form.namaPetugas"
        disabled
      ></b-form-input>
    </b-form-group>
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
    BFormGroup,
    BFormTextarea,
  },

  props: {
    registration: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: {
        kartuIdentitas: "",
        riwayatDonorSebelumnya: "",
        riwayatDonorTerakhir: "",
        jumlahDonor: "",
        macamDonor: "",
        namaPetugas: "Admin Petugas",
      },
    };
  },

  watch: {
    registration: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.initializeForm(newVal);
        }
      },
    },
  },

  methods: {
    initializeForm(registration) {
      this.form.kartuIdentitas =
        this.normalizeValue(registration.validation) || "";
      this.form.riwayatDonorSebelumnya = registration.prev_history || "";
      this.form.riwayatDonorTerakhir = registration.last_donor_date || "";
      this.form.jumlahDonor = registration.donor_times || "";
      this.form.macamDonor = this.normalizeValue(registration.donor_type) || "";
      this.form.namaPetugas = "Admin";
    },
    normalizeValue(value) {
      if (typeof value === "string") {
        return value.toLowerCase();
      }
      return value;
    },
  },
};
</script>
