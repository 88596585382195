<template>
  <div class="p-3">
    <h4 class="font-weight-bold">Kuisioner</h4>
    <b-container fluid>
      <b-row
        v-for="question in questions"
        :key="question.question_id"
        class="mb-2"
      >
        <b-col cols="9">
          <p class="mb-0">{{ question.description }}</p>
        </b-col>
        <b-col cols="3">
          <b-form-checkbox-group
            v-model="question.answer"
            :options="checkboxOptions"
            disabled
          ></b-form-checkbox-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { BContainer, BRow, BCol, BFormCheckboxGroup } from "bootstrap-vue";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormCheckboxGroup,
  },
  props: {
    questionnaire_list: {
      type: Array,
      required: true,
    },
    registration_question_answer: {
      type: String, // Expecting the answer data as a JSON string
      required: true,
    },
  },
  data() {
    return {
      questions: [],
      checkboxOptions: [
        { text: "Ya", value: "YA" },
        { text: "Tidak", value: "TIDAK" },
      ],
    };
  },
  created() {
    this.initializeQuestions();
  },
  watch: {
    questionnaire_list: {
      handler() {
        this.initializeQuestions();
      },
      immediate: true,
    },
    registration_question_answer: {
      handler() {
        this.initializeQuestions();
      },
      immediate: true,
    },
  },
  methods: {
    // Function to parse the doubly-stringified registration_question_answer
    parseRegistrationAnswer() {
      try {
        // First, parse the outer JSON string
        const firstParsed = JSON.parse(this.registration_question_answer);
        // Then parse the resulting string into an object
        return JSON.parse(firstParsed);
      } catch (error) {
        console.error("Failed to parse registration_question_answer:", error);
        return [];
      }
    },

    initializeQuestions() {
      const answers = this.parseRegistrationAnswer(); // Call the parsing function

      this.questions = this.questionnaire_list.map((question) => {
        const answerItem = answers.find(
          (answer) => answer.question_id == question.id
        );

        return {
          question_id: question.id,
          description: question.description,
          answer: answerItem ? [answerItem.answer] : [], // Set the answer if found
        };
      });
    },
  },
};
</script>
