<template>
  <b-container fluid class="py-3">
    <h5>DIISI OLEH PENDONOR</h5>
    <b-row>
      <b-col cols="12" md="6">
        <b-form-group label="No.KTP/SIM/Passpor">
          <b-form-input
            type="number"
            v-model="form.noId"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Nama Lengkap">
          <b-form-input type="text" v-model="form.nama" disabled></b-form-input>
        </b-form-group>

        <b-form-group label="Alamat Rumah">
          <b-form-textarea
            rows="4"
            v-model="form.alamatRumah"
            disabled
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label="No.Telp Rumah/HP">
          <b-form-input
            type="text"
            v-model="form.noTelpRumah"
            disabled
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6">
        <b-form-group label="No.Kartu Donor">
          <b-form-input
            type="text"
            v-model="form.noKartuDonor"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Jenis Kelamin">
          <b-form-radio-group v-model="form.jenisKelamin" disabled>
            <b-form-radio value="male">Laki-laki</b-form-radio>
            <b-form-radio value="female">Perempuan</b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <b-form-group label="Kelurahan">
          <b-form-input
            type="text"
            v-model="form.kelurahan"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Kecamatan">
          <b-form-input
            type="text"
            v-model="form.kecamatan"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Kota">
          <b-form-input type="text" v-model="form.kota" disabled></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group label="Alamat Kantor">
          <b-form-input
            type="text"
            v-model="form.alamatKantor"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Telp Kantor">
          <b-form-input
            type="text"
            v-model="form.telpKantor"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Pekerjaan">
          <b-form-radio-group v-model="form.pekerjaan" disabled>
            <b-form-radio value="tni/polri">TNI/POLRI</b-form-radio>
            <b-form-radio value="petani/buruh">Petani/Buruh</b-form-radio>
            <b-form-radio value="mahasiswa">Mahasiswa</b-form-radio>
            <b-form-radio value="pegawai Negeri">Pegawai Negeri</b-form-radio>
            <b-form-radio value="wiraswasta">Wiraswasta</b-form-radio>
            <b-form-radio value="pedagang">Pedagang</b-form-radio>
            <b-form-radio value="lain-lain">Lain-lain</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <b-row>
          <b-col>
            <b-form-group label="Tempat Kelahiran">
              <b-form-input
                type="text"
                v-model="form.tempatKelahiran"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Tanggal Kelahiran">
              <b-form-input
                type="date"
                v-model="form.tanggalKelahiran"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group label="Penghargaan yang telah diterima">
          <b-form-radio-group v-model="form.penghargaan" disabled>
            <b-form-radio value="10x">10x</b-form-radio>
            <b-form-radio value="25x">25x</b-form-radio>
            <b-form-radio value="50x">50x</b-form-radio>
            <b-form-radio value="75x">75x</b-form-radio>
            <b-form-radio value="100x">100x</b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <b-form-group label="Bersediakah anda donor pada waktu bulan puasa">
          <b-form-radio-group v-model="form.bersediaDonor" disabled>
            <b-form-radio value="1">Ya</b-form-radio>
            <b-form-radio value="0">Tidak</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
    BFormGroup,
    BFormTextarea,
  },
  data() {
    return {
      form: {
        noId: "",
        nama: "",
        alamatRumah: "",
        noTelpRumah: "",
        alamatKantor: "",
        noKartuDonor: "",
        jenisKelamin: "",
        kelurahan: "",
        kecamatan: "",
        kota: "",
        telpKantor: "",
        pekerjaan: "",
        tempatKelahiran: "",
        tanggalKelahiran: "",
        penghargaan: "",
        bersediaDonor: "",
      },
    };
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  watch: {
    user: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.initializeForm(newVal);
        }
      },
    },
  },
  methods: {
    initializeForm(user) {
      this.form.noId = user.nik || "";
      this.form.nama = user.name || "";
      this.form.alamatRumah = user.address || "";
      this.form.noTelpRumah = user.phone || "";
      this.form.alamatKantor = user.address_office || "";
      this.form.noKartuDonor = user.no_card || "";
      this.form.jenisKelamin = user.sex || "";
      this.form.kelurahan = user.village || "";
      this.form.kecamatan = user.district || "";
      this.form.kota = user.city || "";
      this.form.telpKantor = user.phone_office || "";
      this.form.pekerjaan = this.normalizeValue(user.job) || "";
      this.form.tempatKelahiran = user.birth_place || "";
      this.form.tanggalKelahiran = user.birth_date || "";
      this.form.penghargaan = user.award || "";
      this.form.bersediaDonor = this.normalizeValue(user.ready_donor) || "";
    },
    normalizeValue(value) {
      if (typeof value === "string") {
        return value.toLowerCase();
      }
      return value;
    },
  },
};
</script>
