<template>
  <b-container fluid class="py-3">
    <h5>DIISI OLEH DOKTER</h5>
    <b-form>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Nama Dokter">
            <b-form-input
              id="nama-dokter"
              v-model="form.namaDokter"
              disabled
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Berat Badan">
            <b-form-input
              id="berat-badan"
              v-model="form.beratBadan"
              disabled
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Keadaan Umum">
            <b-form-input
              id="keadaan-umum"
              v-model="form.keadaanUmum"
              disabled
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Denyut Nadi">
            <b-form-input
              id="denyut-nadi"
              v-model="form.denyutNadi"
              disabled
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Tinggi Badan">
            <b-form-input
              id="tinggi-badan"
              v-model="form.tinggiBadan"
              disabled
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Suhu">
            <b-form-input id="suhu" v-model="form.suhu" disabled></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Riwayat Medis">
        <b-form-input
          id="riwayat-medis"
          v-model="form.riwayatMedis"
          disabled
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Metode Pengambilan Darah">
        <b-form-input
          id="metode"
          v-model="form.metodePengambilan"
          disabled
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Ditolak / Diambil sebanyak">
        <b-form-input
          id="diambil"
          v-model="form.diambil"
          disabled
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Kantong">
        <b-form-input
          id="kantong"
          v-model="form.kantong"
          disabled
        ></b-form-input>
      </b-form-group>
    </b-form>
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BForm,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BForm,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      form: {
        namaDokter: "",
        beratBadan: "",
        keadaanUmum: "",
        denyutNadi: "",
        tinggiBadan: "",
        suhu: "",
        riwayatMedis: "",
        metodePengambilan: "",
        diambil: "",
        kantong: "",
      },
    };
  },
  props: {
    doctor_examination: {
      type: Object,
      required: true,
    },
  },
  watch: {
    doctor_examination: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.initializeForm(newVal);
        }
      },
    },
  },
  methods: {
    initializeForm(doctor_examination) {
      this.form.namaDokter = doctor_examination.name_doctor || "";
      this.form.beratBadan = doctor_examination.weight
        ? `${doctor_examination.weight} kg`
        : "";
      this.form.keadaanUmum = doctor_examination.general_condition || "";
      this.form.denyutNadi = doctor_examination.pulse
        ? `${doctor_examination.pulse} bpm`
        : "";
      this.form.tinggiBadan = doctor_examination.height
        ? `${doctor_examination.height} cm`
        : "";
      this.form.suhu = doctor_examination.temperature
        ? `${doctor_examination.temperature} ℃`
        : "";
      this.form.riwayatMedis = doctor_examination.medical_history || "";
      this.form.metodePengambilan = doctor_examination.collection_method || "";
      this.form.diambil = doctor_examination.cc
        ? `${doctor_examination.cc} cc`
        : "";
      this.form.kantong = doctor_examination.bag || "";
    },
  },
};
</script>
