<template>
  <b-container
    fluid
    class="py-3"
  >
    <h5>DIISI OLEH PETUGAS AFTAP</h5>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group label="No. Kantong">
          <b-form-input
            id="kanton"
            v-model="form.noKantong"
            disabled
          />
        </b-form-group>
        <b-form-group label="No. Selang">
          <b-form-input
            id="noSelang"
            v-model="form.noSelang"
            disabled
          />
        </b-form-group>
        <b-form-group label="Nama Petugas Aftap">
          <b-form-input
            id="petugas"
            v-model="form.namaPetugas"
            disabled
          />
        </b-form-group>
        <b-form-group label="Jam Penusukan">
          <b-row>
            <b-col
              class="d-flex justify-content-between align-items-center"
              style="gap: 1rem"
            >
              <b-form-timepicker
                id="mulai"
                v-model="form.jamMulai"
                disabled
              />

              <span>s/d</span>

              <b-form-timepicker
                id="selesai"
                v-model="form.jamSelesai"
                disabled
              />
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <h6>REAKSI DONOR</h6>
        <b-form-group label="Reaksi Pengambilan">
          <b-form-input
            v-model="form.reaksiPengambilan"
            disabled
          />
        </b-form-group>
        <b-form-group label="Reaksi Samping">
          <b-form-input
            v-model="form.reaksiSamping"
            type="text"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormTimepicker,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormTimepicker,
  },
  props: {
    aftap_examination: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        noKantong: '',
        noSelang: '',
        namaPetugas: '',
        jamMulai: '',
        jamSelesai: '',
        reaksiPengambilan: '',
        reaksiSamping: '',
      },
      validasiOptions: [
        { text: 'Sudah', value: '1' },
        { text: 'Belum', value: '0' },
      ],
      reaksiPengambilanOptions: [
        { text: 'Lancar', value: 'lancar' },
        { text: 'Aferesis', value: 'aferesis' },
        { text: 'Stop', value: 'stop' },
      ],
      reaksiSampingOptions: [
        { text: 'Tidak Ada', value: 'tidak ada' },
        { text: 'Pusing', value: 'pusing' },
        { text: 'Hematoma', value: 'hematoma' },
        { text: 'Muntah', value: 'muntah' },
        { text: 'Dan lain-lain', value: 'lain-lain' },
      ],
    }
  },
  watch: {
    aftap_examination: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.initializeForm(newVal)
        }
      },
    },
  },
  methods: {
    initializeForm(aftap_examination) {
      this.form.noKantong = aftap_examination.barcode || ''
      this.form.noSelang = aftap_examination.blood_bag_number || ''
      this.form.namaPetugas = aftap_examination.officer_name || ''
      this.form.jamMulai = aftap_examination.venipuncture_start_time || ''
      this.form.jamSelesai = aftap_examination.venipuncture_end_time || ''
      this.form.reaksiPengambilan = this.normalizeValue(aftap_examination.uptake_reaction) || ''
      this.form.reaksiSamping = this.normalizeValue(aftap_examination.side_effect)
          || (aftap_examination.side_effect ? 'lain-lain' : '')
    },
    normalizeValue(value) {
      if (typeof value === 'string') {
        return value.toLowerCase()
      }
      return value
    },
  },
}
</script>
