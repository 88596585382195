<template>
  <div class="p-3">
    <section class="text-center">
      <h3 class="font-weight-bold">
        UNIT TRANSFUSI DARAH RSUD OTO ISKANDAR DI NATA
      </h3>
      <p>
        Cingcin, Kec.Soreang, Kabupaten Bandung, Jawa Barat 40921
        Telp.0831-1205-6201
        <br />
        Formulir Kuisioner dan Informed Consent
      </p>
    </section>

    <section>
      <b-container fluid>
        <b-tabs pills card align="center">
          <b-tab title="Donor" active>
            <Pendonor :user="user" />
          </b-tab>
          <b-tab title="Administrasi">
            <Administrasi :registration="registration" />
          </b-tab>
          <b-tab title="Dokter">
            <Dokter :doctor_examination="doctor_examination" />
          </b-tab>
          <b-tab title="HB">
            <HB :hb_examination="hb_examination" />
          </b-tab>
          <b-tab title="Aftap">
            <Aftap :aftap_examination="aftap_examination" />
          </b-tab>
          <b-tab title="Kuisioner">
            <Questionnaire
              :questionnaire_list="questionnaire_list"
              :registration_question_answer="registration_question_answer"
            />
          </b-tab>
        </b-tabs>
      </b-container>
    </section>
  </div>
</template>

<script>
import {
  BContainer,
  BFormDatepicker,
  BTab,
  BTabs,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import Pendonor from "./components/Pendonor.vue";
import Administrasi from "./components/Administrasi.vue";
import Dokter from "./components/Dokter.vue";
import HB from "./components/HB.vue";
import Aftap from "./components/Aftap.vue";
import Questionnaire from "./components/Questionnaire.vue";
import manageQuestionnaireAPI from "../../../api/managequestionnaire/manageQuestionnaireAPI";
import manageRegistrationReportAPI from "../../../api/report/registrationReportAPI";

export default {
  components: {
    BContainer,
    BFormDatepicker,
    BTab,
    BTabs,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    Pendonor,
    Administrasi,
    Dokter,
    HB,
    Aftap,
    Questionnaire,
  },
  data() {
    return {
      form: {
        blood_bag_number: "",
      },
      user: null,
      registration: null,
      doctor_examination: null,
      hb_examination: null,
      aftap_examination: null,
      registration_questrion_answer: null,
      questionnaire_list: null,
    };
  },
  async mounted() {
    await this.fetchRegistrationReport();
    await this.fetchQuestionnaire();
  },
  methods: {
    async fetchRegistrationReport() {
      try {
        const response = await manageRegistrationReportAPI.getDetail(
          this.$route.params.id
        );
        const data = response.data.data;
        this.user = data.user;
        this.registration = data.registration;
        this.doctor_examination = data.doctor_examination;
        this.hb_examination = data.hb_examination;
        this.aftap_examination = data.aftap_examination;
        this.registration_question_answer = data.registration_question_answer;

        console.log(data.registration_question_answer);
      } catch (error) {
        console.error("Failed to fetch registration report:", error);
      }
    },
    async fetchQuestionnaire() {
      try {
        const response = await manageQuestionnaireAPI.getAll({ per_page: 50 });
        this.questionnaire_list = response.data.data.data;
      } catch (error) {
        console.error("Failed to fetch questionnaire list:", error);
      }
    },
  },
};
</script>
